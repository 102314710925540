<template>
    <div class="p-4">
        <top-search></top-search>

        <div v-if="vendors" class="w-full my-12 px-4 fade-out-up transition duration-200">
            <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1 ">
                            <h3 class="font-semibold text-lg">Restaurants</h3>
                        </div>
                    </div>
                </div>
                <div class="block w-full overflow-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th class="px-4 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">#</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Name</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Address</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">State</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">City</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Vendor</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Date</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Status</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr class="bg-white overflow-auto" v-for="(vendor, index) in vendors" :key="vendor.id">
                                <td class="border-t-0 rounded-md pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    <span class="font-bold"> {{ index + 1 }} </span>
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ vendor.business_name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase truncate">{{ vendor.business_address }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ vendor.state.name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ vendor.city.name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ vendor.user.first_name }} {{ vendor.user.last_name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ moment(vendor.created_at).format("DD-MMM-YYYY") }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ vendor.status }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                    <button @click="id = vendor.id, status = vendor.status, approveVendorRequest()">Approve</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <square-grid v-else class="absolute top-1/2 left-1/2"></square-grid>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import TopSearch from '@/components/TopSearch.vue'
import SquareGrid from '@/components/loaders/SquareGrid.vue'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default {
    name: 'Vendor',
    components: {
        TopSearch,
        SquareGrid
    },
    data() {
        return {
            moment: moment,
            id: '',
            status: ''
        }
    },
    computed: {
        ...mapGetters({vendors: 'vendorsModule/getVendors'}),
        ...mapGetters({update: 'vendorsModule/getVendorUpdate'}),
    },
    methods: {
        approveVendorRequest() {
            const data = { id: this.id, status: this.status }
            this.$store.dispatch('vendorsModule/approveVendorRequest', data)
            .then(() => { 
                if (this.update.status == 'success') {
                    Toast.fire({
                        icon: 'success',
                        title: this.update.message,
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Unabled to update..."
                    })
                    
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Unabled to log you in..."
                })
                
            })
        }
    }
}
</script>